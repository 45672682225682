<template>
    <div>
        <form action="" method="post" @submit.prevent="validateemail">
            <div class="d-flex align-items-center justify-content-center bg-br-primary ht-100v">

                <div class="login-wrapper wd-300 wd-xs-350 pd-25 pd-xs-40 bg-white rounded shadow-base">
                    <div class="signin-logo tx-center tx-28 tx-bold tx-inverse">
                        <img src="@/assets/logo.png" class="img-fluid mg-b-15" />
                    </div>

                    <div v-if="emailNotFound" class="alert alert-danger" role="alert">
                        {{ $t('error.emailnotfound') }}
                    </div>
                    <div class="form-group">
                        <input v-validate="'required|email'" v-model="username" type="text" id="username" name="username" class="form-control" :placeholder="$t('emailaddress')" required>
                    </div><!-- form-group -->

                    <button class="btn btn-info btn-block" id="Submit" name="Submit" type="submit">{{$t('actions.submit')}}</button>
                </div><!-- login-wrapper -->
            </div><!-- d-flex --><!-- d-flex -->
        </form>
    </div>
</template>

<script>
    
    
    import toastr from 'toastr';
    export default {
        name: 'ForgotPassword',
        data() {
            return {
                username: '',
                emailNotFound:'',
                loginFailed: false
            };
        },
        methods: {                  
            async validateemail() {
                if (this.errors.items.length > 0) {
                    console.log('error');
                    return;
                }
                this.emailNotFound = false;
                const client = await this.$api.getClient();
                try {
                    await client.ForgotPassword(this.username);
                    toastr.success(this.$t('success.resetpassword'));
                    this.$router.push({ name: 'login' });
                } catch (err) {
                    this.emailNotFound = true;
                }

            },
        }
    };
</script>