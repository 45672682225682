<template>
    <div v-if="initialized">
        <div class="pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30">
            <div class="row">
                <div class="col-6">
                    <h4 class="tx-gray-800 mg-b-5">{{ $t('title.editprofile') }}</h4>
                </div>
                <div class="col-6">
                    <div class="br-pageheader float-right">
                        <nav class="breadcrumb pd-0 mg-0 tx-12 float-right">
                            <router-link class="breadcrumb-item" :to="{name:'home'}">{{ $t('title.home') }}</router-link>
                            <span class="breadcrumb-item">{{ $t('title.editprofile') }}</span>
                            <span class="breadcrumb-item active">{{record | nameLong}}</span>
                        </nav>
                    </div><!-- br-pageheader -->
                </div>
            </div>
        </div>



        <form @submit.prevent="saveChanges">
            <div class="br-pagebody">
                <div class="br-section-wrapper">
                    <div class="row">
                        <div class="col-12">
                            <h6 class="tx-gray-800 tx-uppercase tx-bold tx-14 mg-b-10">{{ $t('actions.edit') }}</h6>
                            <div class="row mg-t-15">
                                <div class="col-4">
                                    <div class="input-group">
                                        <label class="form-control-label" :for="$t('email')">{{$t('email')}}:</label><span class="tx-danger">*&nbsp;</span>
                                    </div>
                                </div>
                                <div class="col-8 mg-t-10 mg-sm-t-0">
                                    <input  class="form-control form-control-sm" v-model="record.email" name="email" type="email" readonly="record.id > 0" />
                                </div>
                            </div>

                            <div class="row mg-t-15">
                                <div class="col-4 ">
                                    <div class="input-group">
                                        <label class="form-control-label" :for="$t('initials')">{{$t('initials')}}:</label><span class="tx-danger">*&nbsp;</span>
                                    </div>
                                </div>
                                <div class="col-8 mg-t-10 mg-sm-t-0">
                                    <input  class="form-control form-control-sm" v-validate="'required'" v-model="record.initials" name="initials" />
                                </div>
                            </div>

                            <div class="row mg-t-15">
                                <div class="col-4 ">
                                    <div class="input-group">
                                        <label class="form-control-label" :for="$t('firstname')">{{$t('firstname')}}:</label><span class="tx-danger">*&nbsp;</span>
                                    </div>
                                </div>
                                <div class="col-8 mg-t-10 mg-sm-t-0">
                                    <input  class="form-control form-control-sm" v-model="record.firstName" name="firstName" v-validate="'required'" />
                                </div>
                            </div>

                            <div class="row mg-t-15">
                                <div class="col-4 ">
                                    <div class="input-group">
                                        <label class="form-control-label" :for="$t('middlename')">{{$t('middlename')}}:</label>
                                    </div>
                                </div>
                                <div class="col-8 mg-t-10 mg-sm-t-0">
                                    <input  class="form-control form-control-sm" v-model="record.middleName" name="middleName" />
                                </div>
                            </div>

                            <div class="row mg-t-15">
                                <div class="col-4 ">
                                    <div class="input-group">
                                        <label class="form-control-label" :for="$t('lastname')">{{$t('lastname')}}:</label><span class="tx-danger">*&nbsp;</span>
                                    </div>
                                </div>
                                <div class="col-8 mg-t-10 mg-sm-t-0">
                                    <input  class="form-control form-control-sm" v-model="record.lastName" name="lastName" v-validate="'required'" />
                                </div>
                            </div>

                            <div class="row mg-t-30" v-if="setpassword">
                                <div class="col-md-6">
                                    <label class="rdiobox">
                                        <input  class="form-control form-control-sm" name="rdio" type="radio" v-model="sendpassword" value="1">
                                        <span>{{$t('sendinvitation')}}</span>
                                    </label>
                                </div>
                                <div class="col-md-6">
                                    <label class="rdiobox">
                                        <input  class="form-control form-control-sm" name="rdio" type="radio" v-model="sendpassword" value="0">
                                        <span>{{$t('setpassword')}}</span>
                                    </label>
                                </div>
                            </div>
                            <div class="row mg-t-15" v-if='setpassword && sendpassword==0'>
                                <div class="col-4 ">
                                    <div class="input-group">
                                        <label class="form-control-label" :for="$t('password')">{{$t('password')}}:</label>
                                    </div>
                                </div>
                                <div class="col-8 mg-t-10 mg-sm-t-0">
                                    <input  class="form-control form-control-sm" v-validate="'required|verify_password'" ref="user.password" v-model="record.password" type="password" name="user.password" :placeholder="$t('newpassword')" required>
                                    <p v-show="errors.has('user.password')" class="help is-danger">{{ errors.first('user.password') }}</p>
                                </div>
                            </div>
                            <div class="row mg-t-15" v-if='setpassword && sendpassword==0'>
                                <div class="col-4 ">
                                    <div class="input-group">
                                        <label class="form-control-label" :for="$t('confirmpassword')">{{$t('confirmpassword')}}:</label>
                                    </div>
                                </div>
                                <div class="col-8 mg-t-10 mg-sm-t-0">
                                    <input  class="form-control form-control-sm" v-validate="'required|confirmed:user.password'" v-model="confirmpassword" type="password" name="confirmpassword" :placeholder="$t('confirmpassword')" required>
                                </div>
                            </div>

                            <div class="row mg-t-30">
                                <div class="col-sm-8 mg-l-auto text-right">
                                    <div class="form-layout-footer">
                                        <button v-if="!setpassword" @click="showpassworddialog" type="button" class="btn btn-info mg-r-15">{{ $t('title.changepassword') }}</button>
                                        <button class="btn btn-primary" id="Submit" name="Submit" type="submit">Opslaan</button>
                                    </div><!-- form-layout-footer -->
                                </div><!-- col-8 -->
                            </div>
                        </div><!-- card-body -->
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

    import toastr from 'toastr';
    import checkBeforeLeaveMixin from '@/mixin/index.js';
    import changepassword from '@/components/dialogs/ChangePassword.vue';
    
    export default {
        name: 'EditProfile',
        mixins: [checkBeforeLeaveMixin],
        data() {
            return {
                initialized: false,
                record: {
                    id: -1,
                    email: '',
                    initials: '',
                    password: '',
                    lastName: '',
                    firstName: '',
                    middleName: '',
                    isActive: true
                },
                isvalidate: false,
                confirmpassword: '',
                password: '',
                setpassword: false,
                sendpassword: 1,
                sendpasswordupdate: 0,
            };
        },
        mounted() {
            this.fetch();
        },
        watch: {
            '$route'() {
                this.fetch();
            },
            record: {
                handler() {
                    this.setDirty(true);
                },
                deep: true
            },
            confirmpassword() {
                this.isvalidate = this.errors.any();
            },
        },
        computed: {
            validatedata() {
                return this.errors.any();
            },
        },
        methods: {
            async fetch() {
                const client = await this.$api.getClient();
                const resp = await client.GetCurrentUser();
                this.record = resp.data;
                this.active = this.record.lockoutEnd == null;
                this.initialized = true;
                this.setDirty(false);
            },
            async saveChanges() {
                if (this.errors.items.length > 0) {
                    return;
                }
                this.setDirty(false);
                const client = await this.$api.getClient();
                try {
                    await client.UpdateUser(this.record.id, this.record);
                    toastr.success(this.$t('success.saveChanges'), '');
                } catch (err) {
                    toastr.error(this.$t('error.saveChanges'), err);
                }
            },

            showpassworddialog() {
                this.$vuedals.open({
                    title: this.$t('title.changepassword'),
                    props: {
                        id: parseInt(this.record.id, 10),
                    },
                    component: changepassword,
                    size: 'md',
                    escapable: true,
                });
            },

        }
    };
</script>