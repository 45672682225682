<template>
    <div>
        <form action="" method="post" @submit.prevent="login">
            <div class="d-flex align-items-center justify-content-center bg-br-primary ht-100v">

                <div class="login-wrapper wd-300 wd-xs-350 pd-25 pd-xs-40 bg-white rounded shadow-base">
                    <div class="signin-logo tx-center tx-bold tx-inverse">
                        <img src="@/assets/logo.png" class="img-fluid mg-b-15" />
                    </div>
                    
                    <div v-if="loginFailed" class="alert alert-bordered alert-danger" role="alert">
                        {{$t('status.loginfailed')}}
                    </div>
                    <div class="form-group">
                        <input v-validate="'required|email'" v-model="username" type="text" id="username" name="username" class="form-control" :placeholder="$t('emailaddress')" required>
                    </div><!-- form-group -->
                    <div class="form-group">
                        <input v-validate="'required'" v-model="password" type="password" id="password" name="password" class="form-control" :placeholder="$t('password')" required>                        
                        <router-link :to="{name:'forgotpassword'}" class="tx-info tx-12 d-block mg-t-10">{{ $t('forgotpassword') }}</router-link>
                    </div><!-- form-group -->
                    <button v-if="errors.any()" class="btn btn-info btn-block disabled" disabled>{{ $t('actions.login') }}</button>
                    <button v-else class="btn btn-info btn-block" id="Submit" name="Submit" type="submit">{{ $t('actions.login') }}</button>
                </div><!-- login-wrapper -->
            </div><!-- d-flex -->
        </form>
    </div>
</template>

<script>
import { AUTH_REQUEST } from '@/store/actions/authactions';
import { mapActions } from 'vuex';

export default {
    name: 'Login',
    data() {
        return {
            username: '',
            password: '',
            loginFailed: false
        };
    },
    methods: {
        async login() {
            this.loginFailed = false;
            const { username, password } = this;
            try {
                await this.doLogin({ username, password });
                window.location.href='/';
            } catch(err) {
                this.loginFailed = true;
            }
        },
        ...mapActions('auth', {
            doLogin: AUTH_REQUEST
        })
    }
};
</script>