<template>
    <div>
        <div class="form-layout form-layout-4">

            <div class="row" v-if="sendpasswordupdate==0">
                <div class="col-12">
                    <div class="row mg-t-15">
                        <div class="col-4 ">
                            <div class="input-group">
                                <label class="form-control-label" :for="$t('newpassword')">{{$t('newpassword')}}:</label>
                            </div>
                        </div>
                        <div class="col-8 mg-t-10 mg-sm-t-0">
                            <input v-validate="'required|verify_password'" ref="password" v-focus v-model="password" type="password" name="password" class="form-control" :placeholder="$t('newpassword')" required>
                            <p v-show="errors.has('password')" class="help is-danger">{{ errors.first('password') }}</p>
                        </div>
                    </div>
                    <div class="row mg-t-15">
                        <div class="col-4 ">
                            <div class="input-group">
                                <label class="form-control-label" :for="$t('confirmpassword')">{{$t('confirmpassword')}}:</label>
                            </div>
                        </div>
                        <div class="col-8 mg-t-10 mg-sm-t-0">
                            <input v-validate="'required|confirmed:password'" v-model="confirmpassword" type="password" :name="confirmpassword" class="form-control" :placeholder="$t('confirmpassword')" required>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12  mg-t-15">
                            <div class="alert alert-info">{{$t('passwordRequirements')}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row  mg-t-10">
                <div class="col-12 text-center">
                    <button :disabled="!validatedata" @click="updatepassword" type="button" class="btn btn-info mg-r-15">{{ $t('actions.save') }}</button>
                    <button type="button" class="btn btn-success" @focus="dismiss()" @click.prevent="dismiss()">{{ $t('actions.close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import toastr from 'toastr';
    export default {
        name: 'ChangePassword',
        props: {
            id: {
                type: Number
            }
        },
        data() {
            return {
                confirmpassword: '',
                password: '',
                setpassword: false,
                sendpasswordupdate: 0,
            };
        },
       
        computed: {
            validatedata() {
                return this.errors.items.length == 0;
            },
        },
        methods: {
            async updatepassword() {
                if (this.errors.items.length > 0)
                    return;

                let self = this;
                const client = await this.$api.getClient();
                try {
                    await client.UpdatePassword({ id:this.id, sendlink: this.sendpasswordupdate == 1}, { password: this.password });
                    toastr.success(this.$t('success.saveChanges'));
                    self.$vuedals.dismiss();
                } catch (err) {
                    toastr.error(this.$t('error.saveChanges'), err);
                }
            },
            dismiss() {
                this.$vuedals.dismiss();
            },
        }
    };
</script>