<template>
    <div>
        <div class="d-flex align-items-center justify-content-center bg-br-primary ht-100v">
            <div class="login-wrapper wd-300 wd-xs-450 pd-25 pd-xs-40 bg-white rounded shadow-base">
                <div class="signin-logo tx-center tx-28 tx-bold tx-inverse"> <img src="../../assets/logo.png" class="img-fluid" /></div>
                <div class="tx-center mg-b-20">{{ $t('title.portal') }}</div>
                <div v-if="initialized">
                    <div v-if="istokenalive">
                        <formgroup :label="$t('newpassword')" class="mg-t-5 mg-b-0">
                            <input v-validate="'required|verify_password'" ref="password" v-model="password" type="password" name="password" class="form-control" :placeholder="$t('newpassword')" required>
                            <p v-show="errors.has('password')" class="help is-danger">{{ errors.first('password') }}</p>
                        </formgroup>
                        <formgroup :label="$t('confirmpassword')" class="mg-t-5 mg-b-0">
                            <input v-validate="'required|confirmed:password'" v-model="confirmpassword" type="password" :name="confirmpassword" class="form-control" :placeholder="$t('confirmpassword')" required>
                        </formgroup>
                        <div class="row">
                            <div class="col-md-12  mg-t-15">
                                <div class="alert alert-info">{{$t('passwordRequirements')}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12  mg-t-15 text-right">
                                <button :disabled="validatedata" @click="updatepassword" type="button" class="btn btn-primary">{{ $t('actions.save') }}</button>
                            </div>
                        </div>

                    </div>
                    <div v-else class="alert alert-danger" role="alert">
                        {{ $t('status.tokenexpired') }}
                    </div>
                </div>
                <spinner v-else></spinner>
            </div><!-- login-wrapper -->

        </div><!-- d-flex -->
    </div>
</template>

<script>
    
    import toastr from 'toastr';

    export default {
        name: 'SetPassword',
        data() {
            return {
                initialized: false,
                confirmpassword: '',
                password: '',
                user: {},
                istokenalive: true
            };
        },
        mounted() {
            this.fetch();
        },
        watch: {

        },
        computed: {
            validatedata() {
                return this.errors.any() || (this.password.length == 0 || this.confirmpassword.length == 0);
            },

        },
        methods: {
            async fetch() {
                try {
                    const client = await this.$api.getClient();
                    const resp = await client.ValidateUser(this.$route.query.uniqid);
                    this.user = resp.data;
                    this.initialized = true;
                }
                catch (err) {
                    this.initialized = true;
                    this.istokenalive = false;
                }
            },
            async   updatepassword() {
                var arr = { password: this.password };
                const client = await this.$api.getClient();
                try {
                    await client.SetPassword(this.$route.query.uniqid, arr);                    
                    toastr.success(this.$t('success.saveChanges'), '');
                    this.$router.push({ name: 'login' });

                } catch (err) {
                    if (err.response.status === 423) {
                        toastr.error(this.$t('error.emailexists'));
                    }
                    else {
                        toastr.error(this.$t('error.saveChanges'), err);
                    }
                }

            },

        }
    };

</script>